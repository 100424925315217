import { ModalBody } from '@ingka/modal';
import Text from '@ingka/text';
import TextArea from '@ingka/text-area';
import { ItemInfo, LineItemType, ProductAreaNo } from '@resolutions/item-info';
import { useTranslation } from 'react-i18next';
import { useSaveOnUnmount } from '../../../hooks/useSaveOnUnmount/useSaveOnUnmount';
import { useGetOTCState, useSetArticleIssueComment } from '../../../store/features/OTCSlice/OTCSlice';
import { PopItem } from '../../../store/features/OTCSlice/reducerUtils';
import { formatProductNumber } from '../../../utils/productNumber';
import { ArticleIssue } from '../../ArticleIssue/ArticleIssue';
import { OTCArticleQuantity } from '../../OTCArticleTable/OTCArticleQuantity';
import { ConditionOfGoods } from '../../ConditionOfGoods/ConditionOfGoods';

function TextLabel({ text, className }: { text: string, className?: string }) {
  return (
    <Text
      bodySize="m"
      tagName="h3"
      className={`${className} flex justify-between text-dark font-bold`}
    >
      {text}
    </Text>
  );
}

function IssueGroup({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  const setIssue = useSetArticleIssueComment();

  const [comment, setComment] = useSaveOnUnmount({
    value: item.issue?.comment ?? '',
    onUnmount: (liveValue) => setIssue(liveValue, {
      popId: item.popId as string,
      itemLineId: item.itemLineId,
    }),
  });
  return (
    <div className="w-full">
      <TextLabel text={t('otc-item-details-sidebar.issue-group.title')} />
      <ArticleIssue item={item} />
      {item?.issue?.mainReasonCode && (
        <TextArea
          data-testid="otc-item-details-note"
          id="otc-item-details-notes"
          label="Issue comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      )}
    </div>
  );
}

function ConditionOfGoodsGroup({ item }: { item: PopItem }) {
  const { t } = useTranslation();

  return (
    <div>
      <TextLabel text={t('otc-condition-of-goods-sidebar.title')} />
      {item.issue && item.itemType !== 'SGR' ? <ConditionOfGoods item={item} /> : <Text bodySize="m">N/A</Text>}
    </div>
  );
}

function QuantityGroup({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  return (
    <div>
      <TextLabel className="mb-2" text={t('otc-article-table.table-head-columns.quantity')} />
      <OTCArticleQuantity item={item} />
    </div>
  );
}

export function OTCItemDetailsBody() {
  const item = useGetOTCState('articleInSidebar');

  if (!item) {
    return <ModalBody />;
  }

  return (
    <ModalBody>
      <div className="flex flex-col gap-6 items-start">
        <ItemInfo
          className="w-1/2 max-w-[300px]"
          altText={item.productImage?.alt ?? ''}
          itemDescription={item.description}
          itemDimensions={item.dimensions ?? undefined}
          itemName={item.name}
          itemNo={formatProductNumber(item.itemNo)}
          lineItemType={item.itemType as LineItemType}
          productAreaNo={item.productAreaCode as ProductAreaNo}
          productImageUrl={item.productImage?.url}
          quantity={item.quantity}
          showQuantityOnImage
        />
        <hr className={`mt-6 mb-6 [border-block-start-width:1px]
        [border-block-start-style:solid] [border-block-start-color:initial] w-full`}
        />
        <QuantityGroup item={item} />
        <IssueGroup item={item} />
        <ConditionOfGoodsGroup item={item} />
      </div>
    </ModalBody>
  );
}
