import { useTranslation } from 'react-i18next';
import Button from '@ingka/button';
import Text from '@ingka/text';
import { useShowOTCArticleSidebar } from '../../store/features/standAloneActions/actions';
import { PopItem } from '../../store/features/OTCSlice/reducerUtils';
import { UnderlineTextButton } from '../UnderlineTextButton/UnderlineTextButton';

export function ArticleIssue({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  const showOTCArticleSidebar = useShowOTCArticleSidebar();
  const openArticleActionsSidebar = () => showOTCArticleSidebar(item, 'otc-issue-picker');

  if (!item.issue) {
    return (
      <Button
        onClick={openArticleActionsSidebar}
        type="primary"
        size="small"
        text={t('otc-article-table.issue-column.selectIssueBtn')}
      />
    );
  }

  const {
    actor,
    mainReasonCode,
    subReasonCode,
    title,
  } = item.issue;
  const samsString = `SAMS: ${mainReasonCode}${subReasonCode}`;
  const issueString = `${actor}, ${title}`;

  return (
    <UnderlineTextButton
      onClick={openArticleActionsSidebar}
      text={(
        <>
          <Text
            tagName="span"
            bodySize="m"
            className="block text-dark"
          >
            {issueString}
          </Text>
          <Text
            tagName="span"
            bodySize="m"
            className="block text-textAndIcon-4"
          >
            {samsString}
          </Text>
        </>
      )}
    />
  );
}
