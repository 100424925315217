import Button from '@ingka/button';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { PackageCondition, SelectionState } from '@resolutions/condition-of-goods';
import { PopItem } from '../../store/features/OTCSlice/reducerUtils';
import { useShowOTCArticleSidebar } from '../../store/features/standAloneActions/actions';
import { UnderlineTextButton } from '../UnderlineTextButton/UnderlineTextButton';

function getConditionOfGoodsKey({ isAssembled, packageCondition }: SelectionState) {
  if (isAssembled) {
    return 'otc-article-table.issue-column.assembled';
  }

  if (packageCondition === PackageCondition.OPEN_DAMAGED) {
    return 'otc-article-table.issue-column.openDamaged';
  }

  return 'otc-article-table.issue-column.originalPack';
}

export function ConditionOfGoods({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  const showOTCArticleSidebar = useShowOTCArticleSidebar();
  const showConditionOfGoodsModal = () => showOTCArticleSidebar(item, 'otc-condition-of-goods');
  const showConditionOfGoodsButton = item.itemType !== 'SGR';

  if (!showConditionOfGoodsButton || !item.issue) {
    return null;
  }

  return item.conditionOfGoods ? (
    <UnderlineTextButton
      onClick={showConditionOfGoodsModal}
      text={(
        <Text tagName="span" bodySize="m" className="text-dark">
          {t(getConditionOfGoodsKey(item.conditionOfGoods))}
        </Text>
      )}
    />
  ) : (
    <Button
      onClick={showConditionOfGoodsModal}
      type="primary"
      size="xsmall"
      text={t('otc-article-table.issue-column.selectConditionOfGoodsBtn')}
      className="[&_span]:!px-2 [&_span]:!py-1 [&_span]:!min-h-0 mt-2"
    />
  );
}
