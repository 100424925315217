/* eslint-disable arrow-body-style */
import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import {
  InternalQuestionnaireState,
  questionnaireAnswersInitialState as qcInitialState,
  useSelectedReturnMethodState,
  ParsedTimeWindow,
  createCompletedQuestionnaire,
  SelectedReturnMethod,
  IsomReturnOption,
  removeTimeZoneDifference,
} from 'shared-frontend';
import type { RootState } from '../../app/store';
import { useLamnaDispatch, useLamnaSelector } from '../../app/hooks';
import { returnMethodSaved, questionnaireSaved, timeWindowSet } from '../standAloneActions/actions';
import { sidebarSlice } from '../sidebar/sidebarSlice';
import { formatBodyForApi } from '../api/submitReturn/submitReturnUtils';
import { RefundCalcResponse } from '../../../models/refundCalcResponse';
import { CustomerReturn } from '../../../models';
import { SelectedReturnMethodState, SerializedTimeWindow } from './reducerUtils';
import { addApiMatchers } from './apiMatchers';
import { promptSlice } from '../prompt/promptSlice';

const initialQuestionnaireValid = true;
export const initialState: SelectedReturnMethodState = {
  questionnaireCompleted: null,
  questionnaireControl: null,
  selectedReturnMethod: null,
  draft: {
    questionnaireControl: qcInitialState,
    questionnaireValid: initialQuestionnaireValid,
    reschedule: null,
    selectedDateSubOptionData: null,
    selectedReturnMethod: null,
  },
  returnMethodsMarketConfig: null,
};

export const selectedReturnMethodSlice = createSlice({
  name: 'selectedReturnMethod',
  initialState,
  reducers: {
    setSelectedReturnMethodDraft: (
      state: SelectedReturnMethodState,
      action: PayloadAction<SelectedReturnMethod<IsomReturnOption> | null>,
    ) => {
      if (action.payload === null) {
        return;
      }
      state.draft.selectedReturnMethod = action.payload;
    },
    setSelectedDateSubOptionDraft: (
      state: SelectedReturnMethodState,
      action: PayloadAction<{
        timeWindow: SerializedTimeWindow;
      } | null>,
    ) => {
      state.draft.selectedDateSubOptionData = action.payload;
    },
    setQuestionnaireControl: (
      state: SelectedReturnMethodState,
      action: PayloadAction<InternalQuestionnaireState>,
    ) => {
      state.draft.questionnaireControl = action.payload;
    },
    setQuestionnaireValid: (
      state: SelectedReturnMethodState,
      action: PayloadAction<boolean>,
    ) => {
      state.draft.questionnaireValid = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(returnMethodSaved, (state, action) => {
        if (action.payload?.hasQuestionnaire) {
          state.draft.questionnaireControl = qcInitialState;
          state.draft.questionnaireValid = initialQuestionnaireValid;
          state.questionnaireCompleted = null;
        }
        state.selectedReturnMethod = state.draft.selectedReturnMethod;
        state.draft.selectedReturnMethod = null;
      })
      .addCase(timeWindowSet, (state, action) => {
        const draft = state.draft.selectedReturnMethod;
        const newTimeWindow = state.draft.selectedDateSubOptionData?.timeWindow.timeWindowRaw;

        if (!draft || !newTimeWindow) return;

        const updateCallback = (
          updatedSelectedReturnMethod: SelectedReturnMethod<IsomReturnOption>,
        ) => {
          state.draft.selectedReturnMethod = updatedSelectedReturnMethod;
        };

        const [,
          updateSelectedReturnMethodTimeWindow,
        ] = useSelectedReturnMethodState<IsomReturnOption>((
          updatedSelectedReturnMethod,
        ) => {
          if (!updatedSelectedReturnMethod) return;

          updateCallback(updatedSelectedReturnMethod);
        });

        updateSelectedReturnMethodTimeWindow(
          draft,
          newTimeWindow,
        );

        if (action.payload.hasCommittedMethod) {
          state.selectedReturnMethod = state.draft.selectedReturnMethod;
          state.draft.selectedReturnMethod = null;
        }
      })
      .addCase(questionnaireSaved, (state) => {
        const control = state.questionnaireControl
          || state.draft.questionnaireControl;
        const completedQuestionnaire = createCompletedQuestionnaire(control);
        state.questionnaireCompleted = completedQuestionnaire;
        state.questionnaireControl = state.draft.questionnaireControl;
      })
      .addCase(sidebarSlice.actions.closeSidebar, (state, action) => {
        if (action.payload !== 'questionnaire') return;
        state.draft.questionnaireControl = qcInitialState;
        state.draft.questionnaireValid = initialQuestionnaireValid;
        state.draft.selectedDateSubOptionData = null;
      })
      .addCase(sidebarSlice.actions.openSidebarToPage, (state, action) => {
        if (state.selectedReturnMethod) {
          state.draft.selectedReturnMethod = state.selectedReturnMethod;
          if (action.payload.page === 'time-window-calendar' && action.payload.originalTimeWindow) {
            state.draft.reschedule = {
              ...state.draft.reschedule,
              originalTimeWindow: {
                ...action.payload.originalTimeWindow,
                timeWindowId: action.payload.originalTimeWindow.id,
              },
            };
          }
        }
      })
      .addCase(promptSlice.actions.closePrompt, (state, action) => {
        const isRescheduleRetry = action.payload === 'retry-reschedule';
        if (
          isRescheduleRetry
          && state.selectedReturnMethod?.timeWindow
          && state.draft.reschedule?.originalTimeWindow) {
          state.selectedReturnMethod.timeWindow = state.draft.reschedule.originalTimeWindow;
        }
      });
    addApiMatchers(builder);
  },
});

export const useGetSelectedReturnMethodState = <Key extends keyof SelectedReturnMethodState>(
  key: Key,
) => useLamnaSelector((state) => state.selectedReturnMethod[key]);

export const useGetSubmitReturnBody = (
  hasQuestionnaire: boolean,
  refund?: RefundCalcResponse,
) => useLamnaSelector(
  (state) => {
    const { customerReturn, preferredCommunicationMethod, userId } = state.appState;
    const { selectedReturnMethod } = state.selectedReturnMethod;

    if (!customerReturn || !selectedReturnMethod || !userId) {
      return null;
    }

    const questionnaire = state.selectedReturnMethod.questionnaireCompleted;

    const isQuestionnaireValid = !!questionnaire;
    if ((hasQuestionnaire && !isQuestionnaireValid) || !refund) {
      return null;
    }

    return formatBodyForApi({
      customerReturnContent: customerReturn as CustomerReturn,
      hasQuestionnaire,
      preferredCommunicationMethod,
      questionnaire,
      refund,
      selectedReturnMethod,
      userId,
    });
  },
);

// wrap the store action with the hooks from the shared front end that handle data mapping
const useReturnMethodSetters = (
  onSelectedReturnMethod: (
    selectedReturnMethod: SelectedReturnMethod<IsomReturnOption> | null
  ) => void,
) => {
  const [
    selectedReturnMethodSetter,
    updateSelectedReturnMethodTimeWindow,
  ] = useSelectedReturnMethodState(
    onSelectedReturnMethod,
  );
  return {
    selectedReturnMethodSetter,
    updateSelectedReturnMethodTimeWindow,
  };
};

export const useSelectedReturnMethodDraft = () => {
  const dispatch = useLamnaDispatch();
  const setters = useReturnMethodSetters(
    (selectedReturnMethod: SelectedReturnMethod<IsomReturnOption> | null) => {
      dispatch(
        selectedReturnMethodSlice.actions.setSelectedReturnMethodDraft(selectedReturnMethod),
      );
    },
  );

  return {
    setReturnMethodDraft: setters.selectedReturnMethodSetter,
    setReturnMethodTimeWindowDraft: setters.updateSelectedReturnMethodTimeWindow,
  };
};

export const useSetSelectedDateSubOptionDraft = () => {
  const dispatch = useLamnaDispatch();
  return (tw: ParsedTimeWindow) => {
    const timeWindow = {
      ...tw,
      fromDateTime: (tw.timeWindowRaw.fromDateTime),
      toDateTime: (tw.timeWindowRaw.toDateTime),
    };

    return (
      dispatch(selectedReturnMethodSlice.actions.setSelectedDateSubOptionDraft({ timeWindow }))
    );
  };
};

export const useQuestionnaireControl = () => {
  const dispatch = useLamnaDispatch();
  return ({
    setQuestionnaireControl: (questionnaire: InternalQuestionnaireState) => {
      dispatch(selectedReturnMethodSlice.actions.setQuestionnaireControl(questionnaire));
    },
    questionnaireControlState: useLamnaSelector(
      (state: RootState) => state.selectedReturnMethod.questionnaireControl
        || state.selectedReturnMethod.draft.questionnaireControl,
    ),
  });
};

export const useSetQuestionnaireValid = () => {
  const dispatch = useLamnaDispatch();
  return ((isValid: boolean) => {
    dispatch(selectedReturnMethodSlice.actions.setQuestionnaireValid(isValid));
  });
};

export const useGetSelectedReturnMethodDraft = () => {
  const selectDraft = (state: RootState) => state.selectedReturnMethod.draft;

  const selectDraftSelectedSubOptionData = createSelector([
    selectDraft,
  ], (draft) => draft.selectedDateSubOptionData);

  const selectSubOptionDataTimeWindow = createSelector([
    selectDraftSelectedSubOptionData,
  ], (selectedDateSubOptionData) => selectedDateSubOptionData?.timeWindow);

  const selectFromTime = createSelector([
    selectSubOptionDataTimeWindow,
  ], (timeWindow) => timeWindow?.fromDateTime);

  const selectToTime = createSelector([
    selectSubOptionDataTimeWindow,
  ], (timeWindow) => timeWindow?.toDateTime);

  const memoizedSelector = createSelector(
    [
      selectDraft,
      selectDraftSelectedSubOptionData,
      selectSubOptionDataTimeWindow,
      selectFromTime,
      selectToTime,
    ],
    (draft, selectedDateSubOptionData, timeWindow, fromDateTime, toDateTime) => (
      {
        ...draft,
        selectedDateSubOptionData: selectedDateSubOptionData && timeWindow?.timeWindowId ? {
          ...selectedDateSubOptionData,
          timeWindow: {
            ...timeWindow,
            fromDateTime: removeTimeZoneDifference(
              fromDateTime as string,
            ),
            toDateTime: removeTimeZoneDifference(
              toDateTime as string,
            ),
          },
        } : null,
      }
    ),
  );
  return useLamnaSelector(memoizedSelector);
};
