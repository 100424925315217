import { useTranslation } from 'react-i18next';
import TextArea from '@ingka/text-area';
import { useSetArticleIssueComment } from '../../../store/features/OTCSlice/OTCSlice';
import { PopItem } from '../../../store/features/OTCSlice/reducerUtils';
import { useSaveOnUnmount } from '../../../hooks/useSaveOnUnmount/useSaveOnUnmount';
import { TextLabel } from './TextLabel';
import { ArticleIssue } from '../../ArticleIssue/ArticleIssue';

export function IssueGroup({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  const setIssue = useSetArticleIssueComment();

  const [comment, setComment] = useSaveOnUnmount({
    value: item.issue?.comment ?? '',
    onUnmount: (liveValue) => setIssue(liveValue, {
      popId: item.popId as string,
      itemLineId: item.itemLineId,
    }),
  });
  return (
    <div className="w-full">
      <TextLabel text={t('otc-item-details-sidebar.issue-group.title')} />
      <ArticleIssue item={item} />
      {item?.issue?.mainReasonCode && (
      <TextArea
        data-testid="otc-item-details-note"
        id="otc-item-details-notes"
        label="Issue comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      )}
    </div>
  );
}
