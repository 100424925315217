import { ModalBody } from '@ingka/modal';

import { ItemInfo, LineItemType, ProductAreaNo } from '@resolutions/item-info';
import { useGetOTCState } from '../../../store/features/OTCSlice/OTCSlice';
import { formatProductNumber } from '../../../utils/productNumber';
import { IssueGroup } from './IssueGroup';
import { QuantityGroup } from './QuantityGroup';
import { ConditionOfGoodsGroup } from './ConditionOfGoodsGroup';

export function OTCItemDetailsBody() {
  const item = useGetOTCState('articleInSidebar');

  if (!item) {
    return <ModalBody />;
  }

  return (
    <ModalBody>
      <div className="flex flex-col gap-6 items-start">
        <ItemInfo
          className="w-1/2 max-w-[300px]"
          altText={item.productImage?.alt ?? ''}
          itemDescription={item.description}
          itemDimensions={item.dimensions ?? undefined}
          itemName={item.name}
          itemNo={formatProductNumber(item.itemNo)}
          lineItemType={item.itemType as LineItemType}
          productAreaNo={item.productAreaCode as ProductAreaNo}
          productImageUrl={item.productImage?.url}
          quantity={item.quantity}
          showQuantityOnImage
        />
        <hr className={`mt-6 mb-6 [border-block-start-width:1px]
        [border-block-start-style:solid] [border-block-start-color:initial] w-full`}
        />
        <QuantityGroup item={item} />
        <IssueGroup item={item} />
        <ConditionOfGoodsGroup item={item} />
      </div>
    </ModalBody>
  );
}
