import { useTranslation } from 'react-i18next';
import Text from '@ingka/text';
import { PopItem } from '../../../store/features/OTCSlice/reducerUtils';
import { ConditionOfGoods } from '../../ConditionOfGoods/ConditionOfGoods';
import { TextLabel } from './TextLabel';

export function ConditionOfGoodsGroup({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  return (
    <div>
      <TextLabel text={t('otc-condition-of-goods-sidebar.title')} />
      {item.issue && item.itemType !== 'SGR' ? <ConditionOfGoods item={item} /> : <Text bodySize="m">N/A</Text>}
    </div>
  );
}
